import { render, staticRenderFns } from "./animal.vue?vue&type=template&id=3682e2ec&scoped=true"
import script from "./animal.vue?vue&type=script&lang=js"
export * from "./animal.vue?vue&type=script&lang=js"
import style0 from "./animal.vue?vue&type=style&index=0&id=3682e2ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3682e2ec",
  null
  
)

export default component.exports